<template>
  <div class="auth-wrapper auth-v2 min-vh">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="side-panel fade-in">
            <!-- <img
              height="100%"
              width="100%"
              class="side-panel-image"
              :src="require('./assets/landignpage.jpg')"
            /> -->
            <v-img
              :src="
                !$vuetify.theme.isDark
                  ? require('./assets/white-background.jpg')
                  : require('./assets/dark-background.png')
              "
              class="min-vh"
            >
              <v-row
                class="d-flex column justify-center align-center"
                style="min-height: 100vh"
              >
                <v-col cols="6" class="d-flex flex-column">
                  <!-- <h1
                    class="font-weight-bold mb-4 text-h1 slide-in-left"
                    :class="
                      $vuetify.breakpoint.mdAndUp ? 'text-h2 ' : 'text-h2'
                    "
                    style="font-family: 'Urbanist' !important"
                    :style="
                      !$vuetify.theme.isDark && 'color: #FFFFFF !important;'
                    "
                  >
                    {{ $t("Corporate Panel") }}
                  </h1> -->
                  <img
                    class="slide-in-down"
                    :src="
                      !$vuetify.theme.isDark
                        ? require('./assets/dark-logo.svg')
                        : require('./assets/white-logo.svg')
                    "
                    alt="Logo"
                  />
                </v-col>
              </v-row>
            </v-img>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col
              v-if="valid_token"
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text
                  class="d-flex justify-center align-center text-center mb-8"
                  v-if="$vuetify.breakpoint.mdAndDown"
                >
                  <v-img
                    :src="
                      require(`@/assets/Logo_${
                        $vuetify.theme.dark ? 'blanco' : 'negro'
                      }.svg`)
                    "
                    max-width="100%"
                    class=""
                  ></v-img>
                </v-card-text>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t("Enter new password") }}
                  </p>
                  <p class="mb-2">
                    {{ $t("Set the new password and its confirmation") }}.
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <ValidationObserver v-slot="{ invalid }">
                    <v-form @submit.prevent="handleSend()" spellcheck="false">
                      <ValidationProvider
                        :name="$t('Password')"
                        rules="required|min:6|confirmed:password"
                        v-slot="{ errors }"
                        :debounce="450"
                      >
                        <v-text-field
                          v-model="userData.password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          :label="$t('New Password')"
                          :placeholder="$t('New Password')"
                          :append-icon="
                            isPasswordVisible
                              ? icons.mdiEyeOffOutline
                              : icons.mdiEyeOutline
                          "
                          hide-details="auto"
                          class="mb-2"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>
                      <ValidationProvider
                        :name="$t('PasswordNewConfirm')"
                        rules="required|min:6"
                        v-slot="{ errors }"
                        :debounce="450"
                        vid="password"
                      >
                        <v-text-field
                          v-model="userData.passwordConfirm"
                          outlined
                          :type="isPasswordVisibleConfirm ? 'text' : 'password'"
                          :label="$t('PasswordConfirm')"
                          :placeholder="$t('PasswordConfirm')"
                          :append-icon="
                            isPasswordVisibleConfirm
                              ? icons.mdiEyeOffOutline
                              : icons.mdiEyeOutline
                          "
                          hide-details="auto"
                          class="mb-2"
                          @click:append="
                            isPasswordVisibleConfirm = !isPasswordVisibleConfirm
                          "
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </ValidationProvider>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                        rounded
                        :disabled="invalid"
                      >
                        {{ $t("Continue") }}
                      </v-btn>

                      <div
                        class="
                          d-flex
                          align-center
                          justify-space-between
                          flex-wrap
                          mt-4
                        "
                        v-if="true"
                      >
                        <router-link class="ms-3" :to="{ name: 'auth-login' }">
                          <v-icon small>
                            {{ icons.mdiArrowLeft }}
                          </v-icon>
                          <span class="ml-2">{{ $t("Back to the start") }}</span>
                        </router-link>
                        <span>
                          <v-fade-transition mode="out-in" v-if="!false">
                            <v-avatar
                              color="grey-lighten-2"
                              size="36"
                              @click="handleLocale()"
                              class="mySwitcher"
                            >
                              {{
                                locales.find((l) => l.locale === $i18n.locale)
                                  .title
                              }}
                            </v-avatar>
                          </v-fade-transition>

                          <app-bar-theme-switcher
                            class="mx-4"
                          ></app-bar-theme-switcher>
                        </span>
                      </div>
                    </v-form>
                  </ValidationObserver>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-else cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <div v-if="loading" style="position: relative">
                <div>
                  <v-overlay :value="loading" absolute>
                    <v-progress-circular indeterminate />
                  </v-overlay>
                </div>
              </div>
              <div v-else>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t("We're sorry!") }}
                  </p>
                  <p class="mb-2">
                    {{
                      $t(
                        "The link has expired. Request a new link to reset your password"
                      )
                    }}.
                  </p>
                </v-card-text>
                <v-btn color="primary" @click="goToLogin" rounded outlined>
                  {{ $t("Back to the start") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import {
  mdiArrowLeft,
  mdiCheckBold,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import AppBarThemeSwitcher from "@core/layouts/components/app-bar/AppBarThemeSwitcher.vue";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  components: {
    AppBarThemeSwitcher,
  },
  data() {
    return {
      isPasswordVisible: false,
      isPasswordVisibleConfirm: false,

      icons: {
        mdiArrowLeft,
        mdiCheckBold,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      userData: {
        password: "",
        passwordConfirm: "",
      },
      locales: [
        {
          title: "ES",
          locale: "es",
        },
        {
          title: "EN",
          locale: "en",
        },
      ],
      valid_token: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLocale() {
      let prevLocal = this.$i18n.locale;
      let locale = "";
      switch (prevLocal) {
        case "es":
          locale = "en";
          break;
        case "en":
          locale = "es";
          break;
        default:
          break;
      }
      setVeeLocale(locale);
      loadLanguageAsync(locale);
    },
    async handleSend() {
      try {
        this.setLoading(true);
        const resp = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/reset_password`,
          {
            token: this.$route.query.token,
            newPassword: this.userData.password,
            confirmPassword: this.userData.passwordConfirm,
          }
        );
        if (resp.data.success) {
          this.$dialog.notify.success(
            this.$t("Your password has been saved successfully")
          );
          this.goToLogin();
        } else {
          this.$dialog.notify.error(this.$t("Error resetting password"));
        }
      } catch (error) {
        this.setLoading(false);
        this.$dialog.notify.error(this.$t("Error resetting password"));
      } finally {
        this.setLoading(false);
      }
    },
    goToLogin() {
      this.$router.push({ name: "auth-login" });
    },
  },
  async mounted() {
    const token = this.$route.query.token;
    if (token) {
      this.loading = true;
      //validate token
      const params = {
        token: token,
      };
      try {
        const resp = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/validate_token`,
          params
        );
        if (resp.data.success) {
          this.valid_token = true;
        } else {
          this.valid_token = false;
        }
      } catch (error) {
        this.valid_token = false;
      } finally {
        this.loading = false;
      }
    } else {
      this.valid_token = false;
    }
  },
};
</script>
<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
.side-panel {
  // background-image: url("../assets/login_side_panel.png");
  background: white;
  max-height: 100vh;
  &-image {
    object-fit: cover;
    // object-fit: contain;
  }
}
.mySwitcher:hover {
  cursor: pointer;
}
</style>
